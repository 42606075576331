<template>
  <div>
    <div class="alltip">
      <div>
        <div style="text-align: center">
          <img class="loadmimg" src="../../assets/loading.gif" alt="" />
        </div>
        <div class="fonttextclass" style="text-align: center">{{ tipmsg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import base from "../../../util/base";
export default {
  data() {
    return {
      tipmsg: "授权中...",
      allmsg:""
    };
  },
  mounted() {
    this.allmsg = JSON.parse(this.$route.query.params);
    console.log(this.allmsg);
    this.fontmeth();
    console.log(this.allmsg);
    this.toback();
  },
  methods: {
    fontmeth() {
      let a = false;
      setInterval(() => {
        if (a) {
          this.tipmsg = "授权中...";
        } else {
          this.tipmsg = "请稍后...";
        }
        a = !a;
      }, 2000);
    },
    toback() {
      let url, msgobj;
      if (this.allmsg.isqrcode == "true") {
        url = "/firm/v1/Login/setStatus2";
        msgobj = {
          reqType: "Background",
          name: this.allmsg.name,
          id_no: this.allmsg.id_no,
          qr_id: this.allmsg.qr_id,
          mobile: this.allmsg.mobile,
          passport_number: this.allmsg.passport_number,
          certCode: this.allmsg.certCode,
          certId: this.allmsg.certId,
          mobile_code: this.allmsg.mobile_code, //验证是否是本人
          type: "1", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      } else {
        url = "/firm/v1/Login/setStatus1";
        msgobj = {
          reqType: "Background",
          name: this.allmsg.name,
          id_no: this.allmsg.id_no,

          mobile: this.allmsg.mobile,
          passport_number: this.allmsg.passport_number,
          certCode: this.allmsg.certCode,
          certId: this.allmsg.certId,
          type: "1", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      }
      this.$http
        .post(url, msgobj)
        .then((res) => {
          sessionStorage.removeItem("backcenter_time");
          // this.$router.push("/authorizationsuccess")
          if (this.allmsg.pay_type == 6) {
            let aaa;
            if (this.allmsg.isqrcode == "true") {
              this.$paymeth({
                money: this.allmsg.num,
                orderType: 8,
                url: base.url + "/authorizationsuccess",
                id: JSON.parse(res.data).data.l_id,
              });
            } else {
              this.$paymeth({
                money: this.allmsg.num,
                orderType: 8,
                url: base.url + "/authorizationsuccess",
                id: this.allmsg.id,
              });
            }
          } else {
            this.$router.push("/authorizationsuccess");
          }
        })
        .catch((eer) => {
          this.lock = true;
        });
    },
  },
};
</script>
<style scoped>
.alltip {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadmimg {
  width: 3rem;
  height: 3rem;
}
.fonttextclass {
  animation: fontani 1s infinite alternate;
  font-size: 0.28rem;
  font-weight: 600;
  color: #999999;
  line-height: 0.4rem;
  margin-top: 0.08rem;
}
@keyframes fontani {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>